.task-editor {
  background-color: inherit;
  color: inherit;
  width: 100%;
  height: 94%;
  overflow: auto;
  border-style: none;
  border-color: transparent;
  outline: none;
  font-size: 16px;
  line-height: 28px;
  padding: 8px 8px 0; }

.note-editor {
  height: 300px; }
