.task-viewer {
  overflow: auto;
  height: 93%;
  padding: 8px 0; }

.task-viewer__total-performance {
  text-align: right; }

.task-viewer__no-content {
  opacity: .4; }

.task-viewer__today-actions {
  text-align: center;
  padding: 8px 0; }
  .task-viewer__today-actions .btn {
    margin-left: 5px;
    opacity: 0.4;
    border-radius: 14px; }
    .task-viewer__today-actions .btn:hover {
      opacity: 1.0; }
