@charset "UTF-8";
.done-list-viewer {
  height: 94%;
  overflow: auto; }
  .done-list-viewer ul {
    list-style: none; }
  .done-list-viewer li:before {
    content: "• ";
    color: gray; }
  .done-list-viewer .done-list-viewer__daily-panel {
    padding: 8px 12px; }
  .done-list-viewer .done-list-viewer__total-time {
    text-align: right; }
