html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

*, *::before, *::after {
  box-sizing: inherit; }

body {
  margin: 0; }

@media print {
  body {
    background-color: #fff; } }

html, body, #root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: 16px; }

body {
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  /*font-family: Meiryo, sans-serif;*/
  /*color: #2D3142;*/
  /* color: rgba(0,0,0,.84); */ }

h1,
h2,
h3,
h4,
h5 {
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em; }

.l-container {
  height: calc(100% - 52px); }

.l-loginless-container {
  max-width: 100%;
  width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center; }
