.signin {
  width: 960px;
  max-width: 100%;
  margin: 0 auto;
  padding: 40px 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-font-smoothing: antialiased; }
  .signin .signin__title {
    font-size: 50px;
    font-weight: 700;
    margin-top: 12vh;
    margin-bottom: 24px;
    text-align: center;
    line-height: 1.1; }
  .signin .signin__wrap {
    min-width: 320px;
    max-width: 360px;
    margin-bottom: 16vh;
    text-align: center; }
    .signin .signin__wrap .signin__google-auth {
      height: 64px; }
    .signin .signin__wrap .signin__form {
      max-width: 100%; }
    .signin .signin__wrap .signin__form__input {
      margin: 0 0 8px;
      width: 100%; }
    .signin .signin__wrap .signin__form__checkboxlabel {
      text-align: left; }
    .signin .signin__wrap .signin__form__submit {
      margin: 8px 0;
      width: 100%; }
    .signin .signin__wrap .signin__form__error {
      color: red; }
