.todo-cell {
  display: flex;
  padding: 0 4px;
  line-height: 32px; }
  .todo-cell:hover {
    opacity: 0.7; }
    .todo-cell:hover .todo-cell__move-to-today {
      opacity: inherit; }

.todo-cell--top-priority {
  font-weight: bold; }

.todo-cell--less-visible {
  opacity: 0.3; }

.todo-cell--middle-priority {
  opacity: 0.6; }

.todo-cell--low-priority {
  opacity: 0.3; }

.todo-cell__order {
  font-size: 14px;
  min-width: 20px;
  text-align: center;
  padding: 0 4px 0 0; }

.todo-cell__checkbox {
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
  position: relative;
  min-width: 20px;
  cursor: pointer;
  margin: 6px 4px 4px 0; }
  .todo-cell__checkbox:after {
    -webkit-transition: border-color 0.2s linear;
    transition: border-color 0.2s linear;
    position: absolute;
    top: 0%;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 6px;
    content: ''; }
  .todo-cell__checkbox:before {
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
    position: absolute;
    top: 0%;
    left: 5px;
    display: block;
    margin-top: 3px;
    width: 5px;
    height: 9px;
    content: '';
    opacity: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.todo-cell__checkbox--checked:before {
  opacity: 1; }

.todo-cell__title {
  overflow: hidden;
  text-overflow: ellipsis; }

.todo-cell__move-to-today {
  padding: 0 4px;
  opacity: 0; }
  .todo-cell__move-to-today:hover {
    cursor: pointer; }
