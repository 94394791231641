.header {
  width: 100%;
  height: 52px;
  padding: 0px 8px;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }
  .header .header__logo {
    width: 120px;
    margin-bottom: 2px; }
  .header h1 {
    margin: 0;
    margin-right: auto; }
  .header ul {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
    list-style: none;
    margin: 8px 0; }
  .header li:before {
    content: none; }
  .header li + li {
    margin-right: 32px; }
