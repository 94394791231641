.btn {
  display: inline-block;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 4px 12px;
  font-size: 14px;
  border-radius: 4px;
  user-select: none;
  font-weight: 400;
  text-align: center;
  vertical-align: middle; }
