.sign-in {
  text-align: center; }

.logout {
  float: right;
  margin-right: 8px; }
  .logout .logout__button {
    text-decoration: underline;
    margin: 0 5px;
    cursor: pointer; }

.container {
  display: flex;
  height: 100%;
  padding: 0 16px 8px; }

.main,
.sidebar {
  min-width: 360px;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 0; }

.todo-container,
.today-container,
.done-container {
  height: 100%; }
  .todo-container .container-title-group,
  .today-container .container-title-group,
  .done-container .container-title-group {
    display: flex; }
  .todo-container .container-title,
  .today-container .container-title,
  .done-container .container-title {
    font-size: 20px;
    line-height: 32px;
    margin: 8px 0;
    padding: 0 8px;
    border-bottom: 1px solid #eeeeee; }
  .todo-container .container-title--unselected,
  .today-container .container-title--unselected,
  .done-container .container-title--unselected {
    opacity: 0.2; }
